import {
    IOrganizationRule,
    ISearchRulesParams,
} from '@/types';

import AbstractService from './abstract.service';

export class RuleService extends AbstractService {

    public listRules(params: ISearchRulesParams) {
        return new Promise<IOrganizationRule[]>((resolve, reject) => {
            this.guestApi({
                url: `/organizations/${params.organization_id}/rules`,
                method: 'GET',
                params,
            })
            .then((response) => {
                resolve(response.data.organization_rules);
            })
            .catch((error) => {
                reject(error);
            });

        });
    }
}
