export const TYPES_REQUEST = '_typesRequest';
export const SPECIES_REQUEST = '_speciesRequest';
export const BREEDS_REQUEST = '_breedsRequest';
export const SET_TYPES = '_setTypes';
export const SET_SPECIES = '_setSpecies';
export const SET_BREEDS = '_setBreeds';
export const SET_ANIMALS_LIST = '_setAnimalsList';
export const SET_SPECIES_BY_TYPE = '_setSpeciesByType';
export const ADD_ANIMAL = '_addAnimal';
export const UPDATE_ANIMAL = '_updateAnimal';
export const UPDATE_ANIMAL_INSURANCE = '_updateAnimalInsurance';
export const DELETE_ANIMAL = '_deleteAnimal';
export const SET_ANIMALSINSURABLE_LIST = '_setAnimalInsurable';
