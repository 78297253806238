import {IReason} from '@/types';

import AbstractService from './abstract.service';

export class CalendarService extends AbstractService {
    public publicReasonsList() {
        return new Promise<IReason[]>((resolve, reject) => {
            this.guestApi({
                url: `/reasons`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.reasons);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
