
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Watch} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Dictionary} from 'vue-router/types/router';

    import SearchFilters from '@/components/SearchFilters.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import QuickSearchInput from '@/components/forms/QuickSearchInput.vue';
    import SpeciesInput from '@/components/forms/SpeciesInput.vue';
    import {IPlaceSuggestion, ISpecies} from '@/types';
    import {SearchModule} from '@/store/modules/search.module';

    const searchNamespace = namespace('search');

    @Component<TheHeaderSearch>({
        components: {
            PlaceInput,
            QuickSearchInput,
            SearchFilters,
            SpeciesInput,
        },
    })
    export default class TheHeaderSearch extends Vue {
        public location: IPlaceSuggestion|null = null;
        public species: ISpecies|null = null;
        public isClicked: boolean|null = null;


        @searchNamespace.Getter('searchedLocation')
        public searchedLocation!: IPlaceSuggestion|null;

        @searchNamespace.Getter('searchedSpecies')
        public searchedSpecies!: ISpecies|null;

        public mounted() {
            this.location = this.searchedLocation;
            this.species = this.searchedSpecies;
        }

        public handleClick() {
            this.isClicked = !this.isClicked;
        }


        get mapTo() {
            const searchModule = getModule(SearchModule, this.$store);

            return {...searchModule.searchRoute, hash: '#map'};
        }

        get searchTo() {
            const searchModule = getModule(SearchModule, this.$store);

            const searchRoute = searchModule.searchRoute;

            if (this.location) {
                (searchRoute.query as Dictionary<string | null>).latitude = `${this.location.position.latitude}`;
                (searchRoute.query as Dictionary<string | null>).longitude = `${this.location.position.longitude}`;
            }

            if (this.species) {
                (searchRoute.query as Dictionary<string | null>).species = this.species.name.toLowerCase();
            }

            (searchRoute.query as Dictionary<string | null>).page = '1';

            delete searchRoute.hash;

            const toRoute = this.$router.resolve(searchRoute);

            return toRoute.resolved;
        }

        @Watch('searchedLocation')
        private onSearchedLocationChange(val: IPlaceSuggestion|null) {
           this.location = val;
        }

        @Watch('searchedSpecies')
        private onSearchedSpeciesChange(val: ISpecies|null) {
           this.species = val;
        }
    }
