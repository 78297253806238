import {
    IAgenda,
    IBooking,
    IBookingParams,
    IListItem,
    IMoveBookingParams,
    IOrganizationAgendasParams,
    IOrganizationReasonsParams,
    IOrganizationSpecialtiesParams,
    IReason,
    IBookingComment,
    ICreateBookingFilesParams,
    ICreateBookingFilesResult,
    IGetBookingFileParams,
    IDeleteBookingFilesParams,
    IOrganizationSpeciesParams,
    ISpecies,
    IBookingCommentParams,
} from '@/types';

import AbstractService from './abstract.service';

export class BookingService extends AbstractService {
    public getSpecies(params: IOrganizationSpeciesParams) {
        return new Promise<ISpecies[]>((resolve, reject) => {
            const organization_id = params.organization_id;
            delete (params as IOrganizationSpeciesParams).organization_id;

            this.guestApi({
                url: `/organizations/${organization_id}/species?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.species);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getSpecialties(params: IOrganizationSpecialtiesParams) {
        return new Promise<IListItem[]>((resolve, reject) => {
            const organization_id = params.organization_id;
            delete (params as IOrganizationSpecialtiesParams).organization_id;

            this.guestApi({
                url: `/organizations/${organization_id}/specialties?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.specialties);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getReasons(params: IOrganizationReasonsParams) {
        return new Promise<IListItem[]>((resolve, reject) => {
            const organization_id = params.organization_id;
            delete (params as IOrganizationReasonsParams).organization_id;

            this.guestApi({
                url: `/organizations/${organization_id}/reasons?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.reasons);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getAgendas(params: IOrganizationAgendasParams) {
        return new Promise<IAgenda[]>((resolve, reject) => {
            const organization_id = params.organization_id;
            delete (params as IOrganizationAgendasParams).organization_id;

            this.guestApi({
                url: `/organizations/${organization_id}/agendas?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.agendas);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getReasonById(reasonId: string) {
        return new Promise<IReason>((resolve, reject) => {
            this.guestApi({
                url: `/reasons/${reasonId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getAgendaById(agendaId: string) {
        return new Promise<IAgenda>((resolve, reject) => {
            this.guestApi({
                url: `/agendas/${agendaId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public create(params: IBookingParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const client_id = params.client_id;
            delete (params as IBookingParams).client_id;

            this.loggedApi({
                url: `/clients/${client_id}/bookings`,
                method: 'POST',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public list(clientId: string) {
        return new Promise<IBooking[]>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/bookings`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.bookings);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public get(clientId: string, bookingId: string) {
        return new Promise<IBooking>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/bookings/${bookingId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public cancel(clientId: string, bookingId: string) {
        return new Promise<IBooking>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/bookings/${bookingId}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public move(params: IMoveBookingParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const clientId = params.client_id;
            const bookingId = params.booking_id;
            delete (params as IMoveBookingParams).client_id;
            delete (params as IMoveBookingParams).booking_id;

            this.loggedApi({
                url: `/clients/${clientId}/bookings/${bookingId}`,
                method: 'PUT',
                data: params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteBookingFile(params: IDeleteBookingFilesParams) {
        return new Promise<boolean>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${params.client_id}/bookings/${params.booking_id}/files/${params.file_id}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public comment({clientId, bookingId, comment}: IBookingCommentParams) {
        return new Promise<IBookingComment>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/bookings/${bookingId}/comments`,
                method: 'POST',
                data: {comment: comment},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public addFiles(params: ICreateBookingFilesParams) {
        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            const url = `/clients/${params.client_id}/bookings/${params.booking_id}/files`;

            this.loggedApi({
                url,
                method: 'POST',
                data: params.files,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getBookingFile(params: IGetBookingFileParams) {
        return new Promise<string>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${params.client_id}/bookings/${params.booking_id}/files/${params.file_id}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
