import {
    IAccount,
    IAccountResponse,
    ICreateClientData,
    IRefreshTokenParams,
    IRegisterData,
    IUser,
    IUpdateAccountData,
    IUpdatePasswordData,
    IUpdateClientData,
    IUpdateClientDataByPro,
} from '@/types';

import AbstractService from './abstract.service';

export class AccountService extends AbstractService {
    public exists(email: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.guestApi({
                url: `/accounts/exists`,
                params: {
                    type: 'client',
                    email,
                },
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.exists);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public login(email: string, password: string) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.guestApi({
                url: `/authentication`,
                data: {
                    email,
                    password,
                    type: 'client',
                },
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public refreshToken(params: IRefreshTokenParams) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.guestApi({
                url: `/authentication/refresh-token`,
                data: {refresh_token: params.refresh_token},
                method: 'POST',
                headers: {
                    'X-Auth-Failed-No-Retry': true,
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public register(registerData: IRegisterData) {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.guestApi({
                url: `/accounts`,
                data: registerData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public createClient(createClientData: ICreateClientData) {
        return new Promise<IUser>((resolve, reject) => {
            this.loggedApi({
                url: `/clients`,
                data: createClientData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateClient(updateClientData: IUpdateClientData, clientId: string) {
        return new Promise<IUser>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}`,
                data: updateClientData,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateClientByPro(updateClientData: IUpdateClientDataByPro) {
        return new Promise<IUser>((resolve, reject) => {
            const param = {
                first_name: updateClientData.client.first_name,
                email: updateClientData.email,
                cell_phone: updateClientData.cell_phone,
                last_name: updateClientData.client.last_name,
            };

            this.loggedApi({
                url: `organizations/${updateClientData.client.organization_id}/clients/${updateClientData.client.id}`,
                data: param,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updateAccount(updateAccountData: IUpdateAccountData, accountId: string) {
        return new Promise<IUser>((resolve, reject) => {
            this.loggedApi({
                url: `/accounts/${accountId}`,
                data: updateAccountData,
                method: 'PUT',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteAccount(accountId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.loggedApi({
                url: `/accounts/${accountId}`,
                method: 'DELETE',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public resendCode(recaptchaToken: string, accountId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.loggedApi({
                url: `/accounts/${accountId}/resend-code`,
                data: {recaptcha_token: recaptchaToken},
                method: 'POST',
            })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public sendResetPasswordLink(email: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.guestApi({
                url: `/accounts/send-reset-link`,
                data: {
                    email,
                },
                method: 'POST',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public updatePassword(updatePasswordData: IUpdatePasswordData) {
        return new Promise<boolean>((resolve, reject) => {
            this.guestApi({
                url: `/accounts/reset-password`,
                data: updatePasswordData,
                method: 'POST',
            })
                .then((response) => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public verifyPhone(code: string, accountId: string) {
        return new Promise<IAccount>((resolve, reject) => {
            this.loggedApi({
                url: `/accounts/${accountId}/verify`,
                data: {code},
                method: 'POST',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
