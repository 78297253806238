import Vue from 'vue';
import Vuex from 'vuex';
import {
    AccountModule,
    AnimalModule,
    BookingModule,
    CalendarModule,
    SearchModule,
    SnackbarModule,
    TuvcomModule,
    InstructionModule,
    RuleModule,
    ClientModule,
    OrganizationModule,
} from '@/store/modules';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account: AccountModule,
        animal: AnimalModule,
        booking: BookingModule,
        calendar: CalendarModule,
        search: SearchModule,
        snackbar: SnackbarModule,
        tuvcom: TuvcomModule,
        instruction: InstructionModule,
        rule: RuleModule,
        client: ClientModule,
        organization: OrganizationModule
    },
    strict: process.env.NODE_ENV !== 'production',
});
