import {
    IOpeningsSearchParams,
    IOpeningsSearchResult,
    ICheckAvailabilityParams,
} from '@/types';

import AbstractService from './abstract.service';

export class OpeningsService extends AbstractService {
    public doSearch(params: IOpeningsSearchParams) {
        return new Promise<IOpeningsSearchResult>((resolve, reject) => {
            this.guestApi({
                url: `/openings?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public checkAvailability(params: ICheckAvailabilityParams) {
        return new Promise<string[]>((resolve, reject) => {
            this.guestApi({
                url: `/openings/${params.time}?${this.getQueryString(params)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.agendas_ids);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
