import {
    IAnimal,
    IBreed,
    IAnimalFormParams,
    ISpecies,
    IUpdateAnimalInsurance,
    ISVInsuranceRatesGuestParams,
    ISantevetGetQuotationParams
} from '@/types';

import AbstractService from './abstract.service';

export class AnimalService extends AbstractService {
    public typesList() {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.guestApi({
                url: `/animals/types`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.types);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public speciesList() {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.guestApi({
                url: `/animals/species`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.species);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public breedsList() {
        return new Promise<IBreed[]>((resolve, reject) => {
            this.guestApi({
                url: `/animals/breeds`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.breeds);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public list(clientId: string) {
        return new Promise<IAnimal[]>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.animals);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public create(clientId: string, animal: IAnimalFormParams) {
        return new Promise<IAnimal>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals`,
                method: 'POST',
                data: animal,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public createByPro(clientId: string, organizationId: string, animal: IAnimalFormParams) {
        return new Promise<IAnimal>((resolve, reject) => {
            this.loggedApi({
                url: `organizations/${organizationId}/clients/${clientId}/animals`,
                method: 'POST',
                data: animal,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public update(clientId: string, animalId: string, animal: IAnimalFormParams) {
        return new Promise<IAnimal>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals/${animalId}`,
                method: 'PUT',
                data: animal,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public updateInsurance(params: IUpdateAnimalInsurance) {
        return new Promise<IAnimal>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${params.client_id}/animals/${params.animal_id}/insurance`,
                method: 'POST',
                data: {
                    status: params.status,
                    send_information: params.send_information,
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public listForPro(clientId: string, organizationId: string) {
        return new Promise<IAnimal[]>((resolve, reject) => {
            this.loggedApi({
                url: `organizations/${organizationId}/clients/${clientId}/animals`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data.animals);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public santevetInsuranceRates(clientId: string, animalId: string) {
        return new Promise<IAnimal[]>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals/${animalId}/insurance-rates`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public santevetInsuranceRatesGuest(params: ISVInsuranceRatesGuestParams) {
        return new Promise<any>((resolve, reject) => {
            
            this.guestApi({
                url: `/insurance-rates`,
                method: 'POST',
                data: {
                    species_id: params.animal.specie.id,
                    breed_id: params.animal.specie.id,
                    birth_date: params.animal.birth_date,
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public santevetGetQuotation({clientId, animalId, city, zip, cellPhone}: ISantevetGetQuotationParams) {
        return new Promise<any>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals/${animalId}/insurance-quotations?postal_code=${zip}&city=${city}&cellphone=${cellPhone}`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public insuranceAnimalList() {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.guestApi({
                url: `/insurance-animal-list`,
                method: 'GET',
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public santevetGetQuotationGuest(params: any) {
        return new Promise<any>((resolve, reject) => {
            const par = {
                client: {
                    last_name: params.user.lastname,
                    first_name: params.user.firstname,
                    email: params.user.email,
                    cellphone: params.user.cellPhone,
                },
                animal: {
                    name: params.animal.name,
                    birth_date: params.animal.birth_date,
                    species_id: params.animal.specie,
                    breed_id: params.animal.breed,
                    gender: params.animal.gender,
                },
                postal_code: params.user.postalCode,
                city: params.user.city[0],
            };

            this.guestApi({
                url: `/insurance-quotations`,
                method: 'POST',
                data: par,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public remove(clientId: string, animalId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.loggedApi({
                url: `/clients/${clientId}/animals/${animalId}`,
                method: 'DELETE',
            })
            .then((response) => {
                resolve(true);
            })
            .catch((error) => {
                if (error.response.data.error === 'animal_already_deleted') {
                    resolve(true);
                }

                reject(error);
            });
        });
    }
}
