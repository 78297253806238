
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Watch} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import QuickSearchInput from '@/components/forms/QuickSearchInput.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import SpeciesInput from '@/components/forms/SpeciesInput.vue';

    import {
        IListItem,
        IPlaceSuggestion,
        IReason,
        ISpecies,
        SearchRange,
        SearchSort,
    } from '@/types';

    import {SearchModule} from '@/store/modules';

    const calendarNamespace = namespace('calendar');
    const searchNamespace = namespace('search');

    @Component({
        components: {
            PlaceInput,
            SpeciesInput,
            QuickSearchInput,
        },
    })
    export default class SearchFilters extends Vue {
        public sortValues: IListItem[] = [];
        public location: IPlaceSuggestion|null = null;
        public species: ISpecies|null = null;
        public reason: IReason|null = null;
        public range: SearchRange|null = null;
        public sort: SearchSort|null = null;

        @calendarNamespace.Getter('publicReasonsList')
        public publicReasonsList!: IReason[];

        @searchNamespace.Getter('searchedReason')
        public searchedReason!: IReason|null;

        @searchNamespace.Getter('searchedRange')
        public searchedRange!: SearchRange|null;

        @searchNamespace.Getter('searchedSort')
        public searchedSort!: SearchSort|null;

        @searchNamespace.Getter('searchedLocation')
        public searchedLocation!: IPlaceSuggestion|null;

        @searchNamespace.Getter('searchedSpecies')
        public searchedSpecies!: ISpecies|null;

        public mounted() {
            this.sortValues = this.$api.search.getSortValues();
            this.location = this.searchedLocation;
            this.species = this.searchedSpecies;
            this.reason = this.searchedReason;
            this.range = this.searchedRange;
            this.sort = this.searchedSort;
        }

        public goToSearch() {
            const searchModule = getModule(SearchModule, this.$store);

            searchModule.setLocation(this.location);
            searchModule.setSpecies(this.species);
            searchModule.setReason(this.reason);
            searchModule.setRange(this.range);
            searchModule.setSort(this.sort);

            this.$router.push(searchModule.searchRoute);

            this.$emit('close');
        }

        @Watch('searchedLocation')
        private onSearchedLocationChange(val: IPlaceSuggestion|null) {
            this.location = val;
        }

        @Watch('searchedSpecies')
        private onSearchedSpeciesChange(val: ISpecies|null) {
            this.species = val;
        }
    }
