import {IOrganization} from '@/types';

import AbstractService from './abstract.service';

export class OrganizationService extends AbstractService {
    public getBySlug(slug: string) {
        return new Promise<IOrganization>((resolve, reject) => {
            this.guestApi({
                url: `/organizations/${encodeURIComponent(slug)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
