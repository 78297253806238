import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IReason,
} from '@/types';

import {localStorageService} from '@/storage/localstorage.service';

import {
    PUBLIC_REASONS_REQUEST,
    SET_PUBLIC_REASONS,
} from '@/types/store/mutations/calendar.mutations';

import {
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';


@Module({
    namespaced: true,
    name: 'calendar',
})
export class CalendarModule extends VuexModule {
    public status: string|null = null;
    public publicReasons: IReason[] = localStorageService.loadObject('publicReasons', []);

    private publicReasonsRequest: Promise<IReason[]>|null = null;

    get publicReasonsList(): IReason[] {
        return this.publicReasons;
    }

    @Action({rawError: true})
    public async fetchPublicReasons(): Promise<IReason[]> {
        if (this.publicReasons.length > 0) {
            return new Promise<IReason[]>((resolve) => resolve(this.publicReasons));
        }

        if (!this.publicReasonsRequest) {
            this.context.commit(PUBLIC_REASONS_REQUEST, new Promise<IReason[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.calendar
                    .publicReasonsList()
                    .then((response: IReason[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_PUBLIC_REASONS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.publicReasonsRequest as Promise<IReason[]>;
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [PUBLIC_REASONS_REQUEST](promise: Promise<IReason[]>) {
        this.status = 'loading';
        this.publicReasonsRequest = promise;
    }

    @Mutation
    private [SET_PUBLIC_REASONS](data: IReason[]) {
        this.publicReasons = data;
        localStorageService.storeObject('publicReasons', data);
    }
}
