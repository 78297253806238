import { store } from '@/store';
import { IBookingProcessing } from '@/types';

export const bannerHelper = {
    getDynaBan,
};

const defaultBanDesk = require('@/assets/display/dynban/1160x250-default.jpg');
const defaultBanMob = require('@/assets/display/dynban/300x250-default.jpg');
const matchBanDesk = require('@/assets/display/dynban/1160x250-vaccin.jpg');
const matchBanMob = require('@/assets/display/dynban/300x250-vaccin.jpg');

const documentWidth: number = window.innerWidth;
const isDesktop: boolean = documentWidth > 960;
const words: string[] = ['vaccin', 'vaccination'];
const regex: RegExp = new RegExp(words.join("|"), "i");

function getDynaBan(): string {
    //get last reason of booking
    const bookingProcessing: IBookingProcessing = store.getters['booking/bookingProcess'];
    const reason: string | undefined = bookingProcessing.reason?.name;

    //render banner
    if (!reason || !regex.test(reason)) {
        return getDefault(); 
    }
    
    return getMatch();
}

function getDefault(): string {
    return isDesktop ? defaultBanDesk : defaultBanMob;
}

function getMatch(): string {
    return isDesktop ? matchBanDesk : matchBanMob;
}



