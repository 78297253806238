export const formHelper = {
    getEmailsRules,
    getPasswordRules,
    getFirstNameRules,
    getLastNameRules,
    getNameRules,
    getTermsRules,
    getContactSubjectRules,
    getContactMessageRules,
    getValidationCodeRules,
};

function testEmail(email: string) {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

function getEmailsRules() {
    return [
        (v: string) => !!v || 'L\'adresse e-mail est requise',
        (v: string) => testEmail(v) || 'Le format de l\'adresse e-mail est incorrect',
    ];
}

function getValidationCodeRules() {
    return [
        (v: string) => !!v || 'Veuillez entrer le code reçu par SMS',
        (v: string) => /\d{6}/.test(v) || 'Le code doit etre composé de 6 chiffres',
    ];
}

function getPasswordRules(isMandatory: boolean) {
    return isMandatory ?
        [
            (v: string) => !!v || 'Le mot de passe est requis',
            (v: string) => (v && v.length >= 6) || 'Le mot de passe doit contenir au moins 6 caractères',
        ] :
        [
            (v: string) => (!v || v.length >= 6) || 'Le mot de passe doit contenir au moins 6 caractères',
        ]
    ;
}

function getFirstNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer votre prénom',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer votre prénom',
    ];
}

function getLastNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer votre nom de famille',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer votre nom de famille',
    ];
}

function getNameRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer votre nom',
        (v: string) => (v && v.length >= 2) || 'Veuillez indiquer votre nom',
    ];
}

function getTermsRules() {
    return [
        (v: boolean) => v || 'Vous devez accepter les CGU',
    ];
}

function getContactSubjectRules() {
    return [
        (v: string) => !!v || 'Veuillez indiquer l\'objet de votre demande',
    ];
}

function getContactMessageRules() {
    return [
        (v: string) => !!v || 'Veuillez nous donner plus de détails quant à la raison de votre contact',
        (v: string) => (v && v.length >= 50) || 'Votre message est trop court (50 caractères minimum)',
    ];
}
