import Vue from 'vue';
import {
    VuexModule,
    Module,
    Mutation,
    Action,
} from 'vuex-module-decorators';

import {
    IClient,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
  SET_CLIENT_LIST,
} from '@/types/store/mutations/client.mutations';

@Module({
    namespaced: true,
    name: 'client',
})
export class ClientModule extends VuexModule {
    public status: string|null = null;
    public clients: IClient[] = [];

    get clientList(): IClient[] {
        return this.clients;
    }

    @Action({rawError: true})
    public async fetchClients(accountId: string): Promise<IClient[]> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);
            (Vue.prototype as Vue).$api.client.
                getClientsByOrganization(accountId)
                .then((response: IClient[]) => {
                    this.context.commit(SET_CLIENT_LIST, response);
                    resolve(response);
                })
                .catch((error: Error) => {
                    this.context.commit(REQUEST_ERROR, error.message);
                    reject(error);
                })
            ;
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR](errorMessage: string) {
        this.status = 'error';
        console.error(`Request Error: ${errorMessage}`);
    }

    @Mutation
    private [SET_CLIENT_LIST](data: { organizations_clients: IClient[] }) {
        this.clients = data.organizations_clients;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
    }
}
