import Vue from 'vue';
import {DateTime} from 'luxon';

Vue.filter('date', (date: string, format: object, timezone?: string) => {
    if (timezone) {
        return DateTime.fromISO(date, {zone: timezone}).toLocaleString(format);
    }

    return DateTime.fromISO(date).toLocaleString(format);
});
