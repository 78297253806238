import {IClient} from '@/types';

import AbstractService from './abstract.service';

export class ClientService extends AbstractService {
    public getClientsByOrganization(accountId: string) {
        return new Promise<IClient[]>((resolve, reject) => {
            this.loggedApi({
                url: `/accounts/${accountId}/clients`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
