import {IContactData} from '@/types';

import AbstractService from './abstract.service';

export class ContactService extends AbstractService {
    public sendMessage(data: IContactData) {
        return new Promise<Boolean>((resolve, reject) => {
            resolve(true);
            // this.guestApi({
            //     url: `/contact`,
            //     method: 'POST',
            //     data,
            // })
            //     .then((response) => {
            //         resolve(response.data);
            //     })
            //     .catch((error) => {
            //         reject(error);
            //     })
            // ;
        });
    }
}
