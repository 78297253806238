
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {
        IUser,
    } from '@/types';

    import TheHeaderSearch from '@/components/layout/TheHeaderSearch.vue';
    import {AccountModule} from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<TheHeader>({
        props: {
            drawer: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            TheHeaderSearch,
        },
    })
    export default class TheHeader extends Vue {
        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        public newFeature: boolean|string = false;
        public snackbarText: string = '';

        get currentRoute() {
            return this.$route.name;
        }

        get hasExtension() {
            return this.isAccount || this.isSearch;
        }

        get isAccount() {
            return this.$route.matched.some((r) => r.name === 'account');
        }

        get isSearch() {
            return this.$route.matched.some((r) => r.name === 'search');
        }

        get barProps() {

            if (this.$route.matched.some((r) => r.meta.absoluteHeader)) {
                return {
                    absolute: true,
                    app: false,
                    color: 'transparent',
                    elevation: 0,
                };
            }

            return {
                absolute: false,
                app: true,
                color: 'primary',
                hideOnScroll: false,
            };
        }

        public logout() {
            getModule(AccountModule, this.$store)
                .logout()
                .then(() => {
                    if (/\/mon-compte\/.*/.test(window.location.pathname)) {
                        this.$router.push({name: 'home'});
                    }
                    
                })
            ;
        }

        public mounted () {
            this.newFeature = true;
            this.snackbarText = 'Des modifications ont étés apportés aux paramètres du compte'
            if (!this.loggedUser) return;
            if (this.loggedUser.client?.has_optin_insurance !== 'undefined') {
                this.newFeature = false;
            }
            
        }
    }
