
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {
        IUser,
    } from '@/types';
    import {
        bannerHelper,
    } from '@/helpers';

    const accountNamespace = namespace('account');

    @Component({
    })
    export default class TheFooter extends Vue {
        public openDialog: boolean = false;
        public dynaban: string = '';

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        private lists = [
            {
                title: 'Villes',
                links: [
                    {
                        title: 'Paris',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Marseille',
                        url: '/recherche?latitude=43.29338&longitude=5.37132&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Lyon',
                        url: '/recherche?latitude=45.75917&longitude=4.82966&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Strasbourg',
                        url: '/recherche?latitude=48.58504&longitude=7.73643&sort=availability&page=1&per_page=10',
                    },
                ],
            },
            {
                title: 'Animaux',
                links: [
                    {
                        title: 'Chien',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&species=chien&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Chat',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&species=chat&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Chevaux',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&species=cheval&sort=availability&page=1&per_page=10',
                    },
                    {
                        title: 'Oiseaux',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&species=oiseau&sort=availability&page=1&per_page=10',
                    },
                ],
            },
            {
                title: 'Spécialités',
                links: [
                    {
                        title: 'Chiens & Chats',
                        url: '',
                    },
                    {
                        title: 'NAC',
                        url: '',
                    },
                    {
                        title: 'Equidés',
                        url: '/recherche?latitude=48.85718&longitude=2.34141&species=cheval&sort=availability&page=1&per_page=10',
                    },
                ],
            },
        ];

        private bottomLinks = [
            {
                title: 'A propos',
                url: {name: 'about'},
            },
            {
                title: 'Contact',
                url: {name: 'faq'},
            },
            {
                title: 'FAQ',
                url: {name: 'faq'},
            },
            {
                title: 'CGU',
                url: {name: 'terms'},
            },
            {
                title: 'Politique de confidentialité',
                url: {name: 'rgpd'},
            },
            {
                title: 'Mentions légales',
                url: {name: 'legal'},
            },
        ];

        private externalLinks = [
            {
                title: 'L\'ordre des vétérinaires',
                url: 'https://www.veterinaire.fr/',
            }
        ];

        get showContactUs() {
            return this.$route.name && ['contact', 'organization', 'veterinarian'].indexOf(this.$route.name) === -1;
        }

        public created() {
            this.dynaban = bannerHelper.getDynaBan();
        }
    }
