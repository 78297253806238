import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IAccount,
    IAccountResponse,
    ICreateClientData,
    IRegisterData,
    IUpdateAccountData,
    IUpdateClientData,
    IUser,
    IClient,
    IUpdateClientDataByPro,
    IClientCreated,
} from '@/types';

import {localStorageService} from '@/storage/localstorage.service';

import {
    CLEAR_STATE,
    CLEAR_STATUS,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ACCOUNT_UPDATED,
    REFRESH_TOKEN_REQUEST,
    CLIENT_CREATED,
    CLIENT_UPDATED,
    CLIENT_UPDATED_PRO,
} from '@/types/store/mutations/account.mutations';

@Module({
    namespaced: true,
    name: 'account',
})
export class AccountModule extends VuexModule {
    public status: string|null = null;
    public client: IClient | null = localStorageService.loadObject('client');
    public account: IAccount | null = localStorageService.loadObject('account');
    public accessToken: string|null = localStorageService.load('access_token');
    public refreshToken: string|null = localStorageService.load('refresh_token');

    private refreshTokenRequest: Promise<IAccountResponse>|null = null;

    get isLoggedIn(): boolean {
        return !!this.refreshToken;
    }

    get isValidated(): boolean | null {
        return this.account && this.account.status === 'validated';
    }

    get isPendingDetails(): boolean| null {
        return this.account && this.account.status === 'pending_details';
    }

    get isPendingValidation(): boolean| null {
        return this.account && this.account.status === 'pending_validation';
    }

    get isBlacklisted(): boolean| null {
        return this.account && this.account.status === 'blacklisted';
    }

    get loginStatus(): string|null {
        return this.status;
    }

    get loggedUser() {
        return this.isLoggedIn ?
             {
                account: this.account,
                client: this.client,
            } :
            null
        ;
    }

    get loggedClient(): IClient | null {
        return this.client;
    }

    get loggedAccount(): IAccount | null {
        return this.account;
    }

    @Action({rawError: true})
    public async login({username, password}: {username: string, password: string}): Promise<IAccountResponse> {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .login(username, password)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async refreshAccessToken(): Promise<IAccountResponse> {
        if (!this.refreshTokenRequest) {
            this.context.commit(REFRESH_TOKEN_REQUEST, new Promise((resolve, reject) => {
                if (!this.refreshToken) {
                    return reject('Missing refresh token');
                }

                if (!this.loggedAccount) {
                    return reject('No logged account');
                }

                this.context.commit(REQUEST);

                (Vue.prototype as Vue).$api.account
                    .refreshToken({refresh_token: this.refreshToken, account_id: this.loggedAccount.id})
                    .then((response: IAccountResponse) => {
                        this.context.commit(REQUEST_SUCCESS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        const status = error.response?.status;
                        const errorValue = error.response?.data.error;

                        if (status === 401 && errorValue === 'refresh_token_expired') {
                            this.context.dispatch('account/logout', null, {root: true}).then(() => {
                                // TODO: This should use VueRouter, but can't make it work
                                if (/\/mon-compte\/.*/.test(window.location.pathname)) {
                                    window.location.pathname = '/';
                                }
                            });
                        }

                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                    .finally(() => this.context.commit(REFRESH_TOKEN_REQUEST, null))
                ;
            }));
        }

        return this.refreshTokenRequest as Promise<IAccountResponse>;
    }

    @Action({rawError: true})
    public async register(registerData: IRegisterData): Promise<IAccountResponse> {
        return new Promise<IAccountResponse>((resolve, reject) => {
            this.context.commit(REQUEST);
            
            (Vue.prototype as Vue).$api.account
                .register(registerData)
                .then((response: IAccountResponse) => {
                    this.context.commit(REQUEST_SUCCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createClient(clientData: ICreateClientData): Promise<IUser> {
        return new Promise<IUser>((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .createClient(clientData)
                .then((response: IUser) => {
                    this.context.commit(CLIENT_CREATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateClient(clientData: IUpdateClientData): Promise<IUser> {
        return new Promise<IUser>((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);
            if (this.client)
            (Vue.prototype as Vue).$api.account
                .updateClient(clientData, this.client.id)
                .then((response: IUser) => {
                    this.context.commit(CLIENT_UPDATED, response.client);
                    this.context.commit(ACCOUNT_UPDATED, response.account);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateAccount(accountDate: IUpdateAccountData): Promise<IUser> {
        return new Promise<IUser>((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .updateAccount(accountDate, this.account.id)
                .then((response: IUser) => {
                    this.context.commit(ACCOUNT_UPDATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateClientByPro(datas: IUpdateClientDataByPro): Promise<IUser> {
        return new Promise<IUser>((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .updateClientByPro(datas)
                .then((response: IUser) => {
                    this.context.commit(CLIENT_UPDATED, response.client);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteAccount(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!this.account) {
                return reject('You must be logged in');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.account
                .deleteAccount(this.account.id)
                .then((response: boolean) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async verifyPhone(code: string): Promise<IAccount> {
        return new Promise<IAccount>((resolve, reject) => {
            this.context.commit(REQUEST);
            if(this.account)
            (Vue.prototype as Vue).$api.account
                .verifyPhone(code, this.account.id)
                .then((response: IAccount) => {
                    this.context.commit(ACCOUNT_UPDATED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async resendCode(recaptchaToken: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);
            if(this.account)
            (Vue.prototype as Vue).$api.account
                .resendCode(recaptchaToken, this.account.id)
                .then((response: boolean) => {
                    this.context.commit(CLEAR_STATUS);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR, error);
                    reject(error);
                })
            ;
        });
    }

    @Action
    public logout() {
        return new Promise((resolve) => {
            this.context.commit(CLEAR_STATE);
            this.context.commit(`animal/${CLEAR_STATE}`, null, { root: true });
            this.context.commit(`booking/${CLEAR_STATE}`, null, { root: true });
            this.context.commit(`search/${CLEAR_STATE}`, null, { root: true });

            resolve(true);
        });
    }

    @Action
    public clearStatus() {
        this.context.commit(CLEAR_STATUS);
    }

    @Action
    public clientSelectPro(data: IClient) {
        this.context.commit(CLIENT_UPDATED_PRO, data);
    }

    @Mutation
    private [CLIENT_UPDATED_PRO](data: IClient) {
        this.client = data;

        localStorageService.storeObject('client', data);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS](data: IAccountResponse) {
        this.status = 'success';

        if (data.access_token) {
            this.accessToken = data.access_token;
            localStorageService.store('access_token', data.access_token);
        }

        if (data.refresh_token) {
            this.refreshToken = data.refresh_token;
            localStorageService.store('refresh_token', data.refresh_token);
        }

        if (data.account) {
            this.account = data.account;
            localStorageService.storeObject('account', data.account);
        }

        if (data.client) {
            this.client = data.client;
            localStorageService.storeObject('client', data.client);
        }
    }

    @Mutation
    private [CLIENT_CREATED](data: IClientCreated) {
        this.status = 'success';
        if (data.client) {
            this.client = data.client;
            localStorageService.storeObject('client', data.client);
        }
        if (data.account) {
            this.account = data.account;
            localStorageService.storeObject('account', data.account);
        }

    }

    @Mutation
    private [CLIENT_UPDATED](data: IClient) {
        this.status = 'success';

        this.client = data;

        localStorageService.storeObject('client', data);
    }

    @Mutation
    private [ACCOUNT_UPDATED](data: IAccount) {
        this.status = 'success';

        this.account = data;

        localStorageService.storeObject('account', data);
    }

    @Mutation
    private [REFRESH_TOKEN_REQUEST](promise: Promise<IAccountResponse> | null) {
        if (promise) {
            this.status = 'loading';
        }

        this.refreshTokenRequest = promise;
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.accessToken = null;
        this.refreshToken = null;
        this.client = null;
        this.account = null;

        localStorageService.remove('access_token');
        localStorageService.remove('refresh_token');
        localStorageService.remove('client');
        localStorageService.remove('account');
    }

    @Mutation
    private [CLEAR_STATUS]() {
        this.status = null;
    }
}
