import Vue from 'vue';

import {ILocation} from '@/types';

Vue.filter('distance', (location: ILocation, origin: ILocation|null) => {
    if (!origin) {
        return '';
    }

    const R = 6371;
    const dLat = (location.latitude - origin.latitude) * Math.PI / 180;
    const dLon = (location.longitude - origin.longitude) * Math.PI / 180;
    const lat1 = origin.latitude * Math.PI / 180;
    const lat2 = location.latitude * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return `${(R * c).toFixed(2)} km`;
});
