
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {AccountModule} from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<TheDrawer>({
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
        },
    })
    export default class TheDrawer extends Vue {
        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        get currentRoute() {
            return this.$route.name;
        }

        public logout() {
            getModule(AccountModule, this.$store)
                .logout()
                .then(() => {
                    if (this.$route.name !== 'booking') {
                        this.$router.push({name: 'home'});
                    }
                })
            ;
        }
    }
