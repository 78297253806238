import {
    IOrganization,
} from '@/types';

export const addressHelper = {
    getHtmlAddress,
};

function getHtmlAddress(organization: IOrganization) {
    if (!organization.address) {
        return '';
    }

    const postalCode = organization.address.postal_code ? organization.address.postal_code : '';
    const city = organization.address.city ? organization.address.city : '';

    const address = `${postalCode} ${city}`.trim();

    if (organization.organization_type && organization.organization_type.name === 'Vétérinaire à domicile') {
        return address;
    }


    const houseNumber = organization.address.house_number ? organization.address.house_number : '';
    const street = organization.address.street ? organization.address.street : '';

    return `${houseNumber} ${street}<br>${address}`.trim();
}
