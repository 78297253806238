import {
    IBooking,
    IMoveBookingTuvcomParams,
    IBookingComment,
    ICreateBookingFilesTuvcomParams,
    ICreateBookingFilesResult,
    IGetBookingFileTuvcomParams,
    IDeleteBookingFilesTuvcomParams,
} from '@/types';

import AbstractService from './abstract.service';

export class TuvcomService extends AbstractService {
    public get(token: string) {
        return new Promise<IBooking>((resolve, reject) => {
            this.guestApi({
                url: `/tuvcom/booking`,
                method: 'GET',
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public cancel(token: string) {
        return new Promise<IBooking>((resolve, reject) => {
            this.guestApi({
                url: `/tuvcom/booking`,
                method: 'DELETE',
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public move(params: IMoveBookingTuvcomParams) {
        return new Promise<IBooking>((resolve, reject) => {
            const token = params.token;
            delete (params as IMoveBookingTuvcomParams).token;

            this.guestApi({
                url: `/tuvcom/booking`,
                method: 'PUT',
                data: params,
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public deleteBookingFile(params: IDeleteBookingFilesTuvcomParams) {
        const token = params.token;

        return new Promise<boolean>((resolve, reject) => {
            this.guestApi({
                url: `/tuvcom/booking/files/${params.file_id}`,
                method: 'DELETE',
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public comment(token: string, value: string) {
        return new Promise<IBookingComment>((resolve, reject) => {
            this.guestApi({
                url: `/tuvcom/booking/comments`,
                method: 'POST',
                data: {comment: value},
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public addFiles(params: ICreateBookingFilesTuvcomParams) {
        const token = params.token;

        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            this.guestApi({
                url: `/tuvcom/booking/files`,
                method: 'POST',
                data: params.files,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Tuvcom ${token}`,
                },
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getBookingFile(params: IGetBookingFileTuvcomParams) {
        return new Promise<string>((resolve, reject) => {
            const token = params.token;

            this.guestApi({
                url: `/tuvcom/booking/files/${params.file_id}`,
                method: 'GET',
                headers: {Authorization: `Tuvcom ${token}`},
            })
                .then((response) => {
                    resolve(response.data.url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
