import Vue from 'vue';
import {DateTime} from 'luxon';

Vue.filter('age', (date: string|null) => {
    if (!date) {
        return 'Non renseigné';
    }

    const birthDate = DateTime.fromISO(date);
    const now = DateTime.local();
    const diff = now.diff(birthDate, ['years', 'months', 'days']);

    if (diff.years > 1) {
        return `${diff.years} ans`;
    }

    if (diff.years === 1) {
        return `1 an`;
    }

    if (diff.months >= 1) {
        return `${diff.months} mois`;
    }

    if (diff.days >= 0) {
        return `${Math.round(diff.days)} jours`;
    }

    return 'Naissance à venir';
});
