import {IVeterinarian} from '@/types';

import AbstractService from './abstract.service';

export class AgendaService extends AbstractService {
    public getBySlug(slug: string) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.guestApi({
                url: `/agendas/${encodeURIComponent(slug)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getById(agendaId: string) {
        return new Promise<IVeterinarian>((resolve, reject) => {
            this.guestApi({
                url: `/agendas/${agendaId}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
