import Vue from 'vue';
import {
    VuexModule,
    Module,
    Mutation,
    Action,
} from 'vuex-module-decorators';

import {
    IOrganizationRule,
    ISearchRulesParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    SET_RULES_LIST,
    SET_ACCEPTED_RULES,
} from '@/types/store/mutations/rule.mutations';

@Module({
    namespaced: true,
    name: 'rule',
})
export class RuleModule extends VuexModule {
    public status: string|null = null;
    public rules: IOrganizationRule[] = [];
    public rulesAccepted: [] = [];

    get rulesList(): IOrganizationRule[] {
        return this.rules;
    }

    get acceptedRulesList(): [] {
        return this.rulesAccepted;
    }

    @Action({rawError: true})
    public async fetchRules(params: ISearchRulesParams): Promise<IOrganizationRule[]> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.rule
                .listRules(params)
                .then((response: IOrganizationRule[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_RULES_LIST, response);
                    resolve(response);
                })
                .catch((error: any) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public bindRulesAccepted(params: string[]) {
        this.context.commit(SET_ACCEPTED_RULES, params);
    }


    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_ACCEPTED_RULES](data: any) {
        this.rulesAccepted = data;
    }

    @Mutation
    private [SET_RULES_LIST](data: IOrganizationRule[]) {
        data.forEach(
            (element: any) => {
                if (typeof element.used_variables === 'object') {
                    for (const variable in element.used_variables) {
                        if (element.used_variables.hasOwnProperty(variable)) {
                            // tslint:disable-next-line:max-line-length
                            element.rule.rule = element.rule.rule?.replace(':?' + variable + ':?', element.used_variables[variable]);
                            // tslint:disable-next-line:max-line-length
                            element.rule.issue_comment = element.rule.issue_comment?.replace(':?' + variable + ':?', element.used_variables[variable]);
                        }
                    }
                }
            },
        );

        this.rules = data;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
    }
}
