import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IBooking,
    IBookingParams,
    IBookingProcessing,
    IBookingComment,
    IBookingFile,
    ICreateBookingFilesResult,
    ICreateBookingFilesParams,
    IDeleteBookingFilesParams,
    IMoveBookingParams,
    IBookingCommentParams,
    ICommentAndFile,
    IOrganizationRule,IOrganizationInstruction
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_BOOKING,
    ADD_BOOKING_COMMENT,
    ADD_BOOKING_FILES,
    ADD_BOOKING_PROCESS,
    ADD_OR_UPDATE_BOOKING,
    CANCEL_BOOKING,
    DELETE_BOOKING_FILE,
    DELETE_BOOKING_FILE_VET,
    MOVE_BOOKING,
    RESET_BOOKING_PROCESS,
    SET_BOOKINGS_LIST,
    SET_CREATED_BOOKING,
    SET_DISPLAYED_BOOKING,
    ADD_BOOKING_FILES_VET,
} from '@/types/store/mutations/booking.mutations';

import {localStorageService} from '@/storage/localstorage.service';

@Module({
    namespaced: true,
    name: 'booking',
})
export class BookingModule extends VuexModule {
    public status: string|null = null;
    public bookings: IBooking[] = [];
    public createdBooking: IBooking|null = null;
    public files: IBookingFile[] = [];
    public filesVet: IBookingFile[] = [];

    public bookingProcessing: IBookingProcessing = localStorageService.loadObject('bookingProcessing', {
        agenda: null,
        animal: null,
        client: null,
        reason: null,
        start: null,
        public_comment: null,
        instructions_valid: [],
        rules_valid: [],
    });

    public displayedBooking: IBooking|null = null;

    get commentsAndFilesList(): ICommentAndFile[] {
        if (!this.displayedBooking) {
            return [];
        }

        return (this.displayedBooking?.public_comments as any)
            .concat(this.displayedBooking?.files)
            .sort((a: any, b: any) => a.inserted_at < b.inserted_at ? -1 : 1);
    }

    get bookingFilesSummary(): IBookingFile[] {
        return this.filesVet;
    }

    get bookingsList(): IBooking[] {
        return this.bookings;
    }

    get statusReq(): string | null {
        return this.status;
    }

    get latestCreatedBooking(): IBooking|null {
        return this.createdBooking;
    }

    get bookingProcess(): IBookingProcessing {
        return this.bookingProcessing;
    }

    @Action({rawError: true})
    public async fetchBookings(clientId: string): Promise<IBooking[]> {
        return new Promise<IBooking[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .list(clientId)
                .then((response: IBooking[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKINGS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchBooking({clientId, bookingId}: {clientId: string, bookingId: string}): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .get(clientId, bookingId)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_OR_UPDATE_BOOKING, response);
                    this.context.commit(SET_DISPLAYED_BOOKING, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createBooking(params: IBookingParams): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .create(params)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING, response);
                    this.context.commit(SET_CREATED_BOOKING, response);
                    //this.context.commit(RESET_BOOKING_PROCESS, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async commentBooking(
        {clientId, bookingId, comment}: IBookingCommentParams,
    ): Promise<IBookingComment> {
        return new Promise<IBookingComment>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .comment({clientId, bookingId, comment})
                .then((response: IBookingComment) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING_COMMENT, {bookingId, comment: response});
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async addFiles(params: ICreateBookingFilesParams): Promise<ICreateBookingFilesResult> {
        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .addFiles(params)
                .then((response: ICreateBookingFilesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING_FILES, response.uploaded);
                    this.context.commit(ADD_BOOKING_FILES_VET, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteFile(params: IDeleteBookingFilesParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .deleteBookingFile(params)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    if (params.isVet) {
                        this.context.commit(DELETE_BOOKING_FILE_VET, params.file_id);
                    } else {
                        this.context.commit(DELETE_BOOKING_FILE, params.file_id);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async moveBooking(params: IMoveBookingParams): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .move(params)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(MOVE_BOOKING, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async cancelBooking(
        {client_id, booking_id}: {client_id: string, booking_id: string},
    ): Promise<IBooking> {
        return new Promise<IBooking>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.booking
                .cancel(client_id, booking_id)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(CANCEL_BOOKING, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async handlerBookingProcess(params: any): Promise<IBookingProcessing> {
        return new Promise<IBookingProcessing>((resolve, reject) => {
            this.context.commit(ADD_BOOKING_PROCESS, params);
            resolve(params);
        });
    }

    @Action({rawError: true})
    public async resetBookingProcess() {
        return new Promise((resolve, reject) => {
            this.context.commit(RESET_BOOKING_PROCESS);
            resolve('done');
        });
    }

    @Mutation
    private [ADD_BOOKING_PROCESS](data: any) {
        if (!this.bookingProcessing) {
            return;
        }

        this.bookingProcessing = Object.assign(this.bookingProcessing, data);
        localStorageService.storeObject('bookingProcessing', this.bookingProcessing);
    }

    @Mutation
    private [RESET_BOOKING_PROCESS]() {
        this.bookingProcessing = {
            agenda: null,
            animal: null,
            client: null,
            reason: null,
            start: null,
            public_comment: null,
            instructions_valid: [],
            rules_valid: [],
            optin_insurance: null,
            santevet_quotation:null,
        };

        this.filesVet = [];
        localStorageService.storeObject('bookingProcessing', this.bookingProcessing);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_BOOKINGS_LIST](data: IBooking[]) {
        this.bookings = data;
    }

    @Mutation
    private [ADD_BOOKING](data: IBooking) {
        this.bookings.push(data);
    }

    @Mutation
    private [ADD_OR_UPDATE_BOOKING](data: IBooking) {
        let found = false;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.bookings.length; ++i) {
            if (this.bookings[i].id === data.id) {
                Vue.set(this.bookings, i, data);
                found = true;
                break;
            }
        }

        if (!found) {
            this.bookings.push(data);
        }
    }

    @Mutation
    private [SET_DISPLAYED_BOOKING](booking: IBooking) {
        booking.data.forEach((instruction: IOrganizationRule|IOrganizationInstruction) => {
            for (const key in instruction.used_variables) {
                if (instruction.used_variables.hasOwnProperty(key)) {
                    instruction.used_variables.map((variable: string) => {
                        if (instruction.post_booking_instruction) {
                            // tslint:disable-next-line:max-line-length
                            instruction.post_booking_instruction = instruction.post_booking_instruction.replace(':?' + Object.keys(variable)[0] + ':?', Object.values(variable)[0]);
                        }
                        if (instruction.pre_booking_instruction) {
                            // tslint:disable-next-line:max-line-length
                            instruction.pre_booking_instruction = instruction.pre_booking_instruction.replace(':?' + Object.keys(variable)[0] + ':?', Object.values(variable)[0]);
                        }
                    });
                }
            }
        });

        this.displayedBooking = booking;
    }

    @Mutation
    private [ADD_BOOKING_COMMENT]({bookingId, comment}: {bookingId: string, comment: IBookingComment}) {
        this.displayedBooking?.public_comments.push(comment);
    }

    @Mutation
    private [ADD_BOOKING_FILES](files: IBookingFile[]) {
        files.forEach((file) => {
            this.displayedBooking?.files.push(file);
        });
    }

    @Mutation
    private [ADD_BOOKING_FILES_VET](files: IBookingFile[]) {
        this.filesVet.push(files[0]);
    }

    @Mutation
    private [DELETE_BOOKING_FILE](data: string) {
        if (this.displayedBooking) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.displayedBooking.files.length; ++i) {
                if (this.displayedBooking.files[i].id === data) {
                    this.displayedBooking.files.splice(i, 1);
                    break;
                }
            }
        }
    }

    @Mutation
    private [DELETE_BOOKING_FILE_VET](data: string) {
        if (this.filesVet) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.filesVet.length; ++i) {
                if (this.filesVet[i].id === data) {
                    this.filesVet.splice(i, 1);
                    break;
                }
            }
        }
    }

    @Mutation
    private [CANCEL_BOOKING](data: IBooking) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.bookings.length; ++i) {
            if (this.bookings[i].id === data.id) {
                this.bookings[i].status = data.status;
                break;
            }
        }
    }

    @Mutation
    private [MOVE_BOOKING](data: IBooking) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.bookings.length; ++i) {
            if (this.bookings[i].id === data.id) {
                this.bookings[i].start = data.start;
                this.bookings[i].end = data.end;
                break;
            }
        }
    }

    @Mutation
    private [SET_CREATED_BOOKING](data: IBooking) {
        this.createdBooking = data;
        localStorage.setItem('booking_id', data.id);
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.bookings = [];
        this.bookingProcessing = {
            agenda: null,
            animal: null,
            client: null,
            reason: null,
            start: null,
            public_comment: null,
            instructions_valid: [],
            rules_valid: [],
            optin_insurance: null,
            santevet_quotation:null,
        };
        this.createdBooking = null;

        localStorageService.remove('bookingProcessing');
    }
}
