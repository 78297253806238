import Vue from 'vue';
import {
    VuexModule,
    Module,
    Mutation,
    Action,
} from 'vuex-module-decorators';

import {
    IOrganization,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    SET_ORGANIZATION,
} from '@/types/store/mutations/organization.mutations';

@Module({
    namespaced: true,
    name: 'organization',
})
export class OrganizationModule extends VuexModule {
    public status: string|null = null;
    public organization!: IOrganization;

    get organizationGet(): IOrganization {
        return this.organization;
    }

    @Action({rawError: true})
    public async getBySlug(params: string): Promise<IOrganization> {
        return new Promise<IOrganization>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.organization
                .getBySlug(params)
                .then((response: IOrganization) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION, response);
                    resolve(response);
                })
                .catch((error: any) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_ORGANIZATION](data: IOrganization) {
        this.organization = data;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
    }
}
