
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';

    import {AnimalModule} from '@/store/modules';
    import {ISpecies, ISpeciesByType} from '@/types';
    import {Model, Watch} from 'vue-property-decorator';

    @Component<SpeciesInput>({})
    export default class SpeciesInput extends Vue {
        public species: ISpecies|null = null;
        public speciesByType: ISpeciesByType[] = [];

        @Model('input', {type: Object}) public readonly value!: ISpecies|null;

        public mounted() {
            const animalModule = getModule(AnimalModule, this.$store);

            animalModule
                .computeSpeciesByTypeList()
                .then((speciesByType) => {
                    this.speciesByType = speciesByType;

                    if (this.value) {
                        this.species = this.value;
                    }
                })
            ;
        }

        @Watch('value')
        private valueChanged(val: ISpecies|null) {
            if (val !== this.species) {
                this.species = val;
            }
        }
    }
