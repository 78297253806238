import Vue from 'vue';

Vue.filter('day', (dayNumber: number) => {
    if (dayNumber === 0) {
        return 'Lundi';
    }
    switch (dayNumber) {
        case 0:
            return 'Lundi';
        case 1:
            return 'Mardi';
        case 2:
            return 'Mercredi';
        case 3:
            return 'Jeudi';
        case 4:
            return 'Vendredi';
        case 5:
            return 'Samedi';
        case 6:
            return 'Dimanche';
    }
});
