import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {DateTime, ToISOTimeOptions} from 'luxon';
import {reqHelper} from '@/helpers';
import {localStorageService} from '@/storage/localstorage.service';

import {
    IAnimal,
    IAnimalFormParams, IAnimalInsurance,
    IAnimalType,
    IBreed,
    IDeleteAnimalParams,
    ISpecies,
    IUpdateAnimalInsurance,
    ISchemaAJV,
    ISVInsuranceRatesGuestParams,
    ISantevetGetQuotationParams,
    ISpeciesByType
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_ANIMAL,
    BREEDS_REQUEST,
    DELETE_ANIMAL,
    SET_ANIMALS_LIST,
    SET_BREEDS,
    SET_SPECIES_BY_TYPE,
    SET_SPECIES,
    SET_TYPES,
    SPECIES_REQUEST,
    TYPES_REQUEST,
    UPDATE_ANIMAL,
    UPDATE_ANIMAL_INSURANCE,
    SET_ANIMALSINSURABLE_LIST,
} from '@/types/store/mutations/animal.mutations';

@Module({
    namespaced: true,
    name: 'animal',
})
export class AnimalModule extends VuexModule {
    public status: string|null = null;
    public animals: IAnimal[] = [];
    public animalsInsurable: ISpecies[] = [];
    public speciesByType: ISpeciesByType[] = localStorageService.loadObject('speciesByType', []);
    public types: IAnimalType[] = localStorageService.loadObject('animalTypes', []);
    public species: ISpecies[] = localStorageService.loadObject('species', []);
    public breeds: IBreed[] = localStorageService.loadObject('breeds', []);

    public speciesUpdatedAt = localStorageService.loadObject('speciesUpdatedAt', null);
    public animalTypesUpdatedAt = localStorageService.loadObject('animalTypesUpdatedAt', null);
    public breedsUpdatedAt = localStorageService.loadObject('breedsUpdatedAt', null);
    public speciesByTypeUpdatedAt = localStorageService.loadObject('speciesByTypeUpdatedAt', null);

    private typesRequest: Promise<IAnimalType[]>|null = null;
    private speciesRequest: Promise<ISpecies[]>|null = null;
    private breedsRequest: Promise<IBreed[]>|null = null;

    get typesList(): IAnimalType[] {
        return this.types;
    }

    get speciesList(): ISpecies[] {
        return this.species;
    }

    get speciesByTypeList(): ISpeciesByType[] {
        return this.speciesByType;
    }

    get breedsList(): IBreed[] {
        return this.breeds;
    }

    get animalsList(): IAnimal[] {
        return this.animals;
    }

    get animalsInsuranceList(): ISpecies[] {
        return this.animalsInsurable;
    }

    @Action({rawError: true})
    public async computeSpeciesByTypeList(): Promise<ISpeciesByType[]> {
        if (this.speciesByType.length > 0 && localStorageService.checkValidity(this.speciesByTypeUpdatedAt)) {
            return new Promise<ISpeciesByType[]>((resolve) => resolve(this.speciesByType));
        }

        return new Promise<ISpeciesByType[]>((resolve, reject) => {
            Promise
                .all([this.fetchTypes(), this.fetchSpecies()])
                .then(([types, species]) => {
                    const typesById = types.reduce((carry: any, type: IAnimalType) => {
                        carry[type.id] = type;
                        return carry;
                    }, {});

                    const speciesByType = species.reduce((carry: any, speciesItem: ISpecies) => {
                        if (!carry[speciesItem.animal_type_id]) {
                            (carry as any)[speciesItem.animal_type_id] = {
                                name: typesById[speciesItem.animal_type_id].name,
                                species: [],
                            };
                        }

                        carry[speciesItem.animal_type_id].species.push(speciesItem);

                        return carry;
                    }, {});

                    const sortedKeys: string[] = Object.keys(speciesByType).sort((a: string, b: string) => {
                        const aName = speciesByType[a].name;
                        const bName = speciesByType[b].name;

                        return aName < bName ? -1 : 1;
                    });

                    const sortedSpeciesByType = sortedKeys.reduce((carry: any[], key: string) => {
                        const typeSpecies = speciesByType[key];
                        const sortedSpecies = typeSpecies.species.sort((a: ISpecies, b: ISpecies) => {
                            return a.name < b.name ? -1 : 1;
                        });

                        return [...carry, {header: typeSpecies.name}, ...sortedSpecies];
                    }, []);

                    this.context.commit(SET_SPECIES_BY_TYPE, sortedSpeciesByType);
                    resolve(sortedSpeciesByType);
                })
                .catch((error) => reject(error));
        });
    }

    @Action({rawError: true})
    public async fetchTypes(): Promise<IAnimalType[]> {
        if (this.types.length > 0 && localStorageService.checkValidity(this.animalTypesUpdatedAt)) {
            return new Promise<IAnimalType[]>((resolve) => resolve(this.types));
        }

        if (!this.typesRequest) {
            this.context.commit(TYPES_REQUEST, new Promise<IAnimalType[]>((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .typesList()
                    .then((response: IAnimalType[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_TYPES, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.typesRequest as Promise<IAnimalType[]>;
    }

    @Action({rawError: true})
    public async fetchSpecies(): Promise<ISpecies[]> {
        if (this.species.length > 0 && localStorageService.checkValidity(this.speciesUpdatedAt)) {
            return new Promise<ISpecies[]>((resolve) => resolve(this.species));
        }

        if (!this.speciesRequest) {
            this.context.commit(SPECIES_REQUEST, new Promise((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .speciesList()
                    .then((response: ISpecies[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_SPECIES, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.speciesRequest as Promise<ISpecies[]>;
    }

    @Action({rawError: true})
    public async fetchSpeciesById(id: string): Promise<ISpecies> {
        return new Promise<ISpecies>((resolve, reject) => {
            this
                .fetchSpecies()
                .then((speciesList: ISpecies[]) => {
                    const species = speciesList.find((el: ISpecies) => {
                        return el.id === id;
                    });

                    if (species) {
                        resolve(species);
                    } else {
                        reject('Species not found');
                    }
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchBreeds(): Promise<IBreed[]> {
        if (this.breeds.length > 0 && localStorageService.checkValidity(this.breedsUpdatedAt)) {
            return new Promise<IBreed[]>((resolve) => resolve(this.breeds));
        }

        if (!this.breedsRequest) {
            this.context.commit(BREEDS_REQUEST, new Promise((resolve, reject) => {
                (Vue.prototype as Vue).$api.animal
                    .breedsList()
                    .then((response: IBreed[]) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(SET_BREEDS, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
            }));
        }

        return this.breedsRequest as Promise<IBreed[]>;
    }

    @Action({rawError: true})
    public async fetchAnimals(clientId: string): Promise<IAnimal[]> {
        return new Promise<IAnimal[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .list(clientId)
                .then((response: IAnimal[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ANIMALS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async santevetInsuranceRates(
        {clientId, animalId}: {clientId: string, animalId: string},
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .santevetInsuranceRates(clientId, animalId)
                .then((response: any) => {

                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async santevetInsuranceRatesGuest({params}: {params: ISVInsuranceRatesGuestParams}): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .santevetInsuranceRatesGuest(params)
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async santevetGetQuotation(
        {clientId, animalId, city, zip, cellPhone}: ISantevetGetQuotationParams,
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .santevetGetQuotation({clientId, animalId, city, zip, cellPhone})
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async santevetGetQuotationGuest({params}: {params: ISVInsuranceRatesGuestParams}): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .santevetGetQuotationGuest(params)
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async insuranceAnimalList(): Promise<any> {
        return new Promise<ISpecies[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .insuranceAnimalList()
                .then((response: ISpecies[]) => {
                    this.context.commit(SET_ANIMALSINSURABLE_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async fetchAnimalsPro(
            {clientId, organizationId}: {clientId: string, organizationId: string | undefined | null},
        ): Promise<IAnimal[]> {
        return new Promise<IAnimal[]>((resolve, reject) => {
            this.context.commit(REQUEST);
            if (organizationId)
            (Vue.prototype as Vue).$api.animal
                .listForPro(clientId, organizationId)
                .then((response: IAnimal[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ANIMALS_LIST, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public updateAnimalInsurance(params: IUpdateAnimalInsurance): Promise<IAnimal> {
        return new Promise<IAnimal>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .updateInsurance(params)
                .then((response: IAnimal) => {
                    this.context.commit(UPDATE_ANIMAL_INSURANCE, {id: params.animal_id, insurance: response});
                    this.context.commit(REQUEST_ERROR);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async createAnimal({client_id, animal}: {client_id: string, animal: IAnimalFormParams}): Promise<IAnimal> {
        return new Promise<IAnimal>((resolve, reject) => {
            this.context.commit(REQUEST);
                (Vue.prototype as Vue).$api.animal
                    .create(client_id, animal)
                    .then((response: IAnimal) => {
                        this.context.commit(REQUEST_SUCCESS);
                        this.context.commit(ADD_ANIMAL, response);
                        resolve(response);
                    })
                    .catch((error) => {
                        this.context.commit(REQUEST_ERROR);
                        reject(error);
                    })
                ;
        });
    }

    @Action({rawError: true})
    public async createAnimalByPro({client_id, organization_id, animal}:
        {client_id: string, organization_id: string, animal: IAnimalFormParams}): Promise<IAnimal> {
        return new Promise<IAnimal>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .createByPro(client_id, organization_id, animal)
                .then((response: IAnimal) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_ANIMAL, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async updateAnimal(
        {client_id, animal_id, animal}: {client_id: string, animal_id: string, animal: IAnimalFormParams},
    ): Promise<IAnimal> {
        return new Promise<IAnimal>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .update(client_id as string, animal_id, animal)
                .then((response: IAnimal) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(UPDATE_ANIMAL, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteAnimal(data: IDeleteAnimalParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.animal
                .remove(data.client.id as string, data.animal.id)
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_ANIMAL, data.animal.id);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [TYPES_REQUEST](promise: Promise<IAnimalType[]>) {
        this.status = 'loading';
        this.typesRequest = promise;
    }

    @Mutation
    private [SPECIES_REQUEST](promise: Promise<ISpecies[]>) {
        this.status = 'loading';
        this.speciesRequest = promise;
    }

    @Mutation
    private [BREEDS_REQUEST](promise: Promise<IBreed[]>) {
        this.status = 'loading';
        this.breedsRequest = promise;
    }

    @Mutation
    private [SET_TYPES](data: IAnimalType[]) {
        const formatOpts: ToISOTimeOptions = {
            suppressMilliseconds: true,
        };

        this.types = data;
        localStorageService.storeObject('animalTypes', data);
        localStorageService.storeObject(
            'animalTypesUpdatedAt',
            DateTime.utc().set({millisecond: 0}).toISO(formatOpts),
        );
    }

    @Mutation
    private [SET_SPECIES](data: ISpecies[]) {
        const formatOpts: ToISOTimeOptions = {
            suppressMilliseconds: true,
        };

        this.species = data;
        localStorageService.storeObject('species', data);
        localStorageService.storeObject(
            'speciesUpdatedAt',
            DateTime.utc().set({millisecond: 0}).toISO(formatOpts),
        );
    }

    @Mutation
    private [SET_BREEDS](data: IBreed[]) {
        const formatOpts: ToISOTimeOptions = {
            suppressMilliseconds: true,
        };

        this.breeds = data;
        localStorageService.storeObject('breeds', data);
        localStorageService.storeObject(
            'breedsUpdatedAt',
            DateTime.utc().set({millisecond: 0}).toISO(formatOpts),
        );
    }

    @Mutation
    private [SET_SPECIES_BY_TYPE](data: ISpeciesByType[]) {
        const formatOpts: ToISOTimeOptions = {
            suppressMilliseconds: true,
        };

        this.speciesByType = data;
        localStorageService.storeObject('speciesByType', data);
        localStorageService.storeObject(
            'speciesByTypeUpdatedAt',
            DateTime.utc().set({millisecond: 0}).toISO(formatOpts),
        );
    }

    @Mutation
    private [SET_ANIMALS_LIST](data: IAnimal[]) {
        const truncatedAnimals = data.map(animal => {
            if (animal.name.length > 13) {
                animal.name = animal.name.slice(0, 13)+'...';
            }
            return animal;
        });
        this.animals = truncatedAnimals;
    }

    @Mutation
    private [SET_ANIMALSINSURABLE_LIST](data: any) {
        this.animalsInsurable = data.species;
    }

    @Mutation
    private [ADD_ANIMAL](data: IAnimal) {
        this.animals.push(data);
    }

    @Mutation
    private [UPDATE_ANIMAL](data: IAnimal) {
        this.animals = this.animals.map((animal: IAnimal) => {
            if (animal.id === data.id) {
                return data;
            }

            return animal;
        });
    }

    @Mutation
    private [UPDATE_ANIMAL_INSURANCE]({id, insurance}: {id: string, insurance: IAnimalInsurance}) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.animals.length; ++i) {
            if (this.animals[i].id === id) {
                this.animals[i].insurance = insurance;
                break;
            }
        }
    }

    @Mutation
    private [DELETE_ANIMAL](animalId: string) {
        this.animals = this.animals.filter((animal: IAnimal) => animal.id !== animalId);
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.animals = [];
    }
}
