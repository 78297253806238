import {
    ISearchParams,
    ISearchResult,
    ISearchOrganizationsBlacklisted,
    IQuickSearchResult,
    IOrganizationsBlacklisted,
    IClient,
} from '@/types';

import AbstractService from './abstract.service';

export class SearchService extends AbstractService {
    public doQuickSearch(val: string) {
        return new Promise<IQuickSearchResult[]>((resolve, reject) => {
            this.guestApi({
                url: `/quick-search?q=${encodeURIComponent(val)}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.suggestions);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public blacklistedOrganizations(params: ISearchOrganizationsBlacklisted) {
        return new Promise<IOrganizationsBlacklisted[]>((resolve, reject) => {
            const clientId = params.client_id;

            delete (params as ISearchOrganizationsBlacklisted).client_id;


            this.loggedApi({
                url: `/clients/${clientId}/status/`,
                method: 'GET',
                params,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public doSearch(params: ISearchParams) {
        return new Promise<ISearchResult>((resolve, reject) => {
            const queryString = Object
                .keys(params)
                .map((key: string) => {
                    if ((params as any)[key] === null) {
                        return '';
                    }

                    return (encodeURIComponent(key) + '=' + encodeURIComponent((params as any)[key]));
                })
                .filter((value: string) => value !== '')
                .join('&')
            ;

            this.guestApi({
                url: `/search?${queryString}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public getSortValues() {
        return [
            {
                id: 'relevance',
                name: 'Pertinence',
            },
            {
                id: 'availability',
                name: 'Disponibilité',
            },
            {
                id: 'distance',
                name: 'Distance',
            },
        ];
    }
}
