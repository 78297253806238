import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {Location} from 'vue-router';

import {
    IPlacePosition,
    IPlaceSuggestion,
    IReason,
    ISearchParams,
    ISearchResult,
    ISpecies,
    SearchRange,
    SearchSort,
    IOrganization,
    ISearchOrganizationsBlacklisted,
    IOrganizationsBlacklisted,
} from '@/types';

import {
    SET_LOCATION,
    SET_RANGE,
    SET_REASON,
    SET_SEARCH_RESULTS,
    SET_SORT,
    SET_SPECIES,
    SET_PAGE,
    SET_PER_PAGE,
    SET_RESULTS_COUNT,
    SET_ORGANIZATION_BLACKLISTED,
} from '@/types/store/mutations/search.mutations';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';


@Module({
    namespaced: true,
    name: 'search',
})
export class SearchModule extends VuexModule {
    public status: string|null = null;
    public results: IOrganization[] = [];
    public location: IPlaceSuggestion|null = null;
    public species: ISpecies|null = null;
    public reason: IReason|null = null;
    public range: SearchRange|null = null;
    public sort: SearchSort|null = null;
    public resultsCount: number = 0;
    public page: number = 1;
    public perPage: number = 10;
    public organizationBlacklisted: IOrganizationsBlacklisted[] = [];

    get searchedLocation(): IPlaceSuggestion|null {
        return this.location;
    }

    get searchedSpecies(): ISpecies|null {
        return this.species;
    }

    get searchedReason(): IReason|null {
        return this.reason;
    }

    get searchedRange(): SearchRange|null {
        return this.range;
    }

    get searchedSort(): SearchSort|null {
        return this.sort;
    }

    get searchedPage(): number {
        return this.page;
    }

    get searchedPerPage(): number {
        return this.perPage;
    }

    get searchedResultsCount(): number {
        return this.resultsCount;
    }

    get searchResults(): IOrganization[] {
        return this.results;
    }

    get getOrganizationBlacklisted(): IOrganizationsBlacklisted[] {
        return this.organizationBlacklisted;
    }

    get searchRoute(): Location  {
        const query: any = {
            range: this.range ? this.range : 'any',
            sort_by: this.sort ? this.sort : 'relevance',
            page: `${this.page}`,
            per_page: `${this.perPage}`,
        };

        if (this.location) {
            query.latitude = `${this.location.position.latitude}`;
            query.longitude = `${this.location.position.longitude}`;
        }

        if (this.species) {
            query.species = this.species.name.toLowerCase();
        }

        if (this.reason) {
            query.reason = this.reason.name.toLowerCase();
        }

        return {
            name: 'search',
            query,
        };
    }

    @Action({rawError: true})
    public async doSearch() {
        return new Promise<ISearchResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            const params: ISearchParams = {
                latitude: this.location ? this.location.position.latitude : null,
                longitude: this.location ? this.location.position.longitude : null,
                reason_id: this.reason ? this.reason.id : null,
                species_id: this.species ? this.species.id : null,
                sort_by: this.sort,
                range: this.range,
                page: this.page,
                per_page: this.perPage,
            };

            (Vue.prototype as Vue).$api.search
                .doSearch(params)
                .then((response: ISearchResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_SEARCH_RESULTS, response.organizations);
                    this.context.commit(SET_RESULTS_COUNT, response.total);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async setBlacklistedOrganizations(params: ISearchOrganizationsBlacklisted) {
        return new Promise<IOrganizationsBlacklisted[]>((resolve, reject) => {
            (Vue.prototype as Vue).$api.search
                .blacklistedOrganizations(params)
                .then((response: IOrganizationsBlacklisted[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_ORGANIZATION_BLACKLISTED, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async setLocationFromCoords(coords: IPlacePosition) {
        if (
            this.location &&
            this.location.position.latitude === coords.latitude &&
            this.location.position.longitude === coords.longitude
        ) {
            return new Promise<IPlaceSuggestion|null>((resolve) => {
                resolve(this.location);
            });
        }

        return new Promise<IPlaceSuggestion>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.place
                .reverseGeocode(coords)
                .then((response: IPlaceSuggestion) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_LOCATION, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public setSpeciesFromName(name: string) {
        if (this.species && this.species.name.toLowerCase() === name.toLowerCase()) {
            return;
        }

        this.context.rootGetters['animal/speciesList'].some((species: ISpecies) => {
            if (species.name.toLowerCase() === name.toLowerCase()) {
                this.context.commit(SET_SPECIES, species);
                return true;
            }
        });
    }

    @Action({rawError: true})
    public setReasonFromName(name: string) {
        if (this.reason && this.reason.name.toLowerCase() === name.toLowerCase()) {
            return;
        }

        this.context.rootGetters['calendar/publicReasonsList'].some((reason: IReason) => {
            if (reason.name.toLowerCase() === name.toLowerCase()) {
                this.context.commit(SET_REASON, reason);
                return true;
            }
        });
    }

    @Action({rawError: true})
    public setLocation(val: IPlaceSuggestion|null) {
        this.context.commit(SET_LOCATION, val);
    }

    @Action({rawError: true})
    public setSpecies(val: ISpecies|null) {
        this.context.commit(SET_SPECIES, val);
    }

    @Action({rawError: true})
    public setReason(val: IReason|null) {
        this.context.commit(SET_REASON, val);
    }

    @Action({rawError: true})
    public setRange(val: SearchRange|null) {
        this.context.commit(SET_RANGE, val);
    }

    @Action({rawError: true})
    public setSort(val: SearchSort|null) {
        this.context.commit(SET_SORT, val);
    }

    @Action({rawError: true})
    public setPage(val: number|null) {
        this.context.commit(SET_PAGE, val);
    }

    @Action({rawError: true})
    public setPerPage(val: number|null) {
        this.context.commit(SET_PER_PAGE, val);
    }

    @Action({rawError: true})
    public setResultsCount(val: number|null) {
        this.context.commit(SET_RESULTS_COUNT, val);
    }


    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_SEARCH_RESULTS](data: IOrganization[]) {
        this.results = data;
    }

    @Mutation
    private [SET_LOCATION](data: IPlaceSuggestion|null) {
        this.location = data;
    }

    @Mutation
    private [SET_SPECIES](data: ISpecies|null) {
        this.species = data;
    }

    @Mutation
    private [SET_REASON](data: IReason|null) {
        this.reason = data;
    }

    @Mutation
    private [SET_RANGE](data: SearchRange|null) {
        this.range = data;
    }

    @Mutation
    private [SET_ORGANIZATION_BLACKLISTED](data: IOrganizationsBlacklisted[]) {
        this.organizationBlacklisted = data;
    }

    @Mutation
    private [SET_SORT](data: SearchSort|null) {
        this.sort = data;
    }

    @Mutation
    private [SET_PAGE](data: number|null) {
        this.page = data ? data : 1;
    }

    @Mutation
    private [SET_PER_PAGE](data: number|null) {
        this.perPage = data ? data : 10;
    }

    @Mutation
    private [SET_RESULTS_COUNT](data: number|null) {
        this.resultsCount = data ? data : 0;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.location = null;
        this.results = [];
    }
}
