import Vue from 'vue';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';

import {
    IBooking,
    IBookingComment,
    IBookingFile,
    ICreateBookingFilesResult,
    IMoveBookingBaseParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    ADD_BOOKING_COMMENT,
    ADD_BOOKING_FILES,
    CANCEL_BOOKING,
    DELETE_BOOKING_FILE,
    MOVE_BOOKING,
    SET_BOOKING,
    SET_TOKEN,
} from '@/types/store/mutations/tuvcom.mutations';

@Module({
    namespaced: true,
    name: 'tuvcom',
})
export class TuvcomModule extends VuexModule {
    public status: string|null = null;
    public token: string|null = null;
    public booking: IBooking|null = null;

    get commentsAndFilesList(): any[] {
        if (!this.booking) {
            return [];
        }

        return (this.booking?.public_comments as any)
            .concat(this.booking?.files)
            .sort((a: any, b: any) => a.inserted_at < b.inserted_at ? -1 : 1);
    }

    get tuvcomToken(): string|null {
        return this.token;
    }

    @Action({rawError: true})
    public async fetchBooking(): Promise<IBooking> {
        return new Promise((resolve, reject) => {
            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.tuvcom
                .get(this.token as string)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_BOOKING, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async commentBooking(
        {clientId, bookingId, comment}: {clientId: string, bookingId: string, comment: string},
    ): Promise<IBookingComment> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            (Vue.prototype as Vue).$api.tuvcom
                .comment(this.token as string, comment)
                .then((response: IBookingComment) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING_COMMENT, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async addFiles(files: FormData): Promise<ICreateBookingFilesResult> {
        return new Promise<ICreateBookingFilesResult>((resolve, reject) => {
            this.context.commit(REQUEST);

            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            (Vue.prototype as Vue).$api.tuvcom
                .addFiles({
                    files,
                    token: this.token as string,
                })
                .then((response: ICreateBookingFilesResult) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(ADD_BOOKING_FILES, response.uploaded);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async deleteFile(fileId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.context.commit(REQUEST);

            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            (Vue.prototype as Vue).$api.tuvcom
                .deleteBookingFile({
                    file_id: fileId,
                    token: this.token as string,
                })
                .then((response: boolean) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(DELETE_BOOKING_FILE, fileId);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
                ;
        });
    }

    @Action({rawError: true})
    public async moveBooking(params: IMoveBookingBaseParams): Promise<IBooking> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            (Vue.prototype as Vue).$api.tuvcom
                .move({
                    token: this.token as string,
                    start: params.start,
                })
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(MOVE_BOOKING, response);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public async cancelBooking(): Promise<IBooking> {
        return new Promise((resolve, reject) => {
            this.context.commit(REQUEST);

            if (!this.token) {
                reject('Not in a tuvcom context');
            }

            (Vue.prototype as Vue).$api.tuvcom
                .cancel(this.token as string)
                .then((response: IBooking) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(CANCEL_BOOKING);
                    resolve(response);
                })
                .catch((error) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public clearState() {
        this.context.commit(CLEAR_STATE);
    }

    @Action({rawError: true})
    public setToken(token: string) {
       this.context.commit(SET_TOKEN, token);
    }

    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_TOKEN](data: string) {
        this.token = data;
    }

    @Mutation
    private [CANCEL_BOOKING]() {
        if (!this.booking) {
            return;
        }

        this.booking.status = 'cancelled_by_client';
    }

    @Mutation
    private [MOVE_BOOKING](data: IBooking) {
        if (!this.booking) {
            return;
        }

        this.booking.start = data.start;
        this.booking.end = data.end;
    }

    @Mutation
    private [SET_BOOKING](data: IBooking) {
        this.booking = data;
    }

    @Mutation
    private [ADD_BOOKING_COMMENT](data: IBookingComment) {
        this.booking?.public_comments.push(data);
    }

    @Mutation
    private [ADD_BOOKING_FILES](files: IBookingFile[]) {
        files.forEach((file) => {
            this.booking?.files.push(file);
        });
    }

    @Mutation
    private [DELETE_BOOKING_FILE](data: string) {
        if (this.booking) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.booking.files.length; ++i) {
                if (this.booking.files[i].id === data) {
                    this.booking.files.splice(i, 1);
                    break;
                }
            }
        }
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
        this.booking = null;
        this.token = null;
    }
}
