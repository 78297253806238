import Vue from 'vue';
import {
    VuexModule,
    Module,
    Mutation,
    Action,
} from 'vuex-module-decorators';

import {
    IOrganizationInstruction,
    ISearchInstructionsParams,
} from '@/types';

import {
    CLEAR_STATE,
    REQUEST,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
} from '@/types/store/mutations/store.mutations';

import {
    SET_INSTRUCTIONS_LIST,
    SET_ACCEPTED_INSTRUCTIONS,
} from '@/types/store/mutations/instruction.mutations';

@Module({
    namespaced: true,
    name: 'instruction',
})
export class InstructionModule extends VuexModule {
    public status: string|null = null;
    public instructions: IOrganizationInstruction[] = [];
    public instructionsAccepted: string[] = [];

    get instructionsList(): IOrganizationInstruction[] {
        return this.instructions;
    }

    get preBookingInstructionsList(): IOrganizationInstruction[] {
        return this.instructions.filter((elm) => !!elm.instruction.pre_booking_instruction);
    }

    get postBookingInstructionsList(): IOrganizationInstruction[] {
        return this.instructions.filter((elm) => !!elm.instruction.post_booking_instruction);
    }

    get acceptedInstructionsList(): string[] {
        return this.instructionsAccepted;
    }

    @Action({rawError: true})
    public async fetchInstructions(params: ISearchInstructionsParams): Promise<IOrganizationInstruction[]> {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            this.context.commit(REQUEST);

            (Vue.prototype as Vue).$api.instruction
                .listInstructions(params)
                .then((response: IOrganizationInstruction[]) => {
                    this.context.commit(REQUEST_SUCCESS);
                    this.context.commit(SET_INSTRUCTIONS_LIST, response);
                    resolve(response);
                })
                .catch((error: any) => {
                    this.context.commit(REQUEST_ERROR);
                    reject(error);
                })
            ;
        });
    }

    @Action({rawError: true})
    public bindInstructionsAccepted(params: string[]) {
        this.context.commit(SET_ACCEPTED_INSTRUCTIONS, params);
    }


    @Mutation
    private [REQUEST]() {
        this.status = 'loading';
    }

    @Mutation
    private [REQUEST_SUCCESS]() {
        this.status = 'success';
    }

    @Mutation
    private [REQUEST_ERROR]() {
        this.status = 'error';
    }

    @Mutation
    private [SET_ACCEPTED_INSTRUCTIONS](data: string[]) {
        this.instructionsAccepted = data;
    }

    @Mutation
    private [SET_INSTRUCTIONS_LIST](data: IOrganizationInstruction[]) {
        data.forEach((element: IOrganizationInstruction) => {
            if (typeof element.used_variables === 'object') {
                for (const variable in element.used_variables) {
                    if (element.used_variables.hasOwnProperty(variable)) {
                        // tslint:disable-next-line:max-line-length
                        element.instruction.post_booking_instruction = element.instruction.post_booking_instruction?.replace(':?' + variable + ':?', element.used_variables[variable]);
                        // tslint:disable-next-line:max-line-length
                        element.instruction.pre_booking_instruction = element.instruction.pre_booking_instruction?.replace(':?' + variable + ':?', element.used_variables[variable]);
                    }
                }
            }
        });

        this.instructions = data;
    }

    @Mutation
    private [CLEAR_STATE]() {
        this.status = null;
    }
}
