import {AxiosInstance} from 'axios';
import {
    IQueryParamsGetter,
} from '@/types';

export default abstract class AbstractService {
    protected loggedApi!: AxiosInstance;
    protected guestApi!: AxiosInstance;

    constructor(loggedApi: AxiosInstance, guestApi: AxiosInstance) {
        this.loggedApi = loggedApi;
        this.guestApi = guestApi;
    }

    protected getQueryString(params: IQueryParamsGetter) {
        return Object
            .keys(params as IQueryParamsGetter)
            .map((key: string) => {
                if ((params as any)[key] === null) {
                    return '';
                }

                return (encodeURIComponent(key) + '=' + encodeURIComponent((params as any)[key]));
            })
            .filter((value: string) => value !== '')
            .join('&')
        ;
    }
}
