export const SET_SEARCH_RESULTS = '_setSearchResults';
export const SET_LOCATION = '_setLocation';
export const SET_SPECIES = '_setSpecies';
export const SET_REASON = '_setReason';
export const SET_RANGE = '_setRange';
export const SET_SORT = '_setSort';
export const SET_PAGE = '_setPage';
export const SET_PER_PAGE = '_setPerPage';
export const SET_RESULTS_COUNT = '_setResultsCount';
export const SET_ORGANIZATION_BLACKLISTED = '_setOrganizationBlacklisted';
