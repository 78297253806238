import {
    IOrganizationInstruction,
    ISearchInstructionsParams,
} from '@/types';

import AbstractService from './abstract.service';

export class InstructionService extends AbstractService {

    public listInstructions(params: ISearchInstructionsParams) {
        return new Promise<IOrganizationInstruction[]>((resolve, reject) => {
            this.guestApi({
                url: `/organizations/${params.organization_id}/instructions`,
                method: 'GET',
                params,
            })
            .then((response) => {
                resolve(response.data.organization_instructions);
            })
            .catch((error) => {
                reject(error);
            });

        });
    }
}
