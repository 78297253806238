import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

import App from '@/App.vue';
import router from '@/router';
import {store} from '@/store';

import '@/filters';

import './registerServiceWorker';

import vuetify from '@/plugins/vuetify';
import ApiPlugin from '@/plugins/api';

import Appsignal from '@appsignal/javascript';
import {errorHandler} from '@appsignal/vue';
import { plugin as networkPlugin } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as decoratorPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as windowPlugin } from '@appsignal/plugin-window-events';
import { plugin as consolePlugin } from "@appsignal/plugin-breadcrumbs-console";

import '@/plugins/recaptcha';
import '@/plugins/firebase';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@/assets/fonts/kievit/kievit.css';
import '@/assets/fonts/neuzeit/neuzeit.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/vetheme/index.scss';

if (process.env.VUE_APP_APPSIGNAL_KEY) {
  const appsignal = new Appsignal({
    key: process.env.VUE_APP_APPSIGNAL_KEY,
    revision: 'v'+process.env.PACKAGE_VERSION,
  });
  appsignal.use(networkPlugin());
  appsignal.use(decoratorPlugin());
  appsignal.use(windowPlugin());
  appsignal.use(consolePlugin());

  Vue.config.errorHandler = errorHandler(appsignal, Vue);
}

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(ApiPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

