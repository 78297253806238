import {AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import axios from 'axios';

import {getModule} from 'vuex-module-decorators';

import {store} from '@/store';
import {AccountModule} from '@/store/modules';

import {
    AccountService,
    AgendaService,
    AnimalService,
    BookingService,
    CalendarService,
    ContactService,
    OpeningsService,
    OrganizationService,
    PlaceService,
    SearchService,
    TuvcomService,
    InstructionService,
    RuleService,
    ClientService,
} from '@/api';

import {localStorageService} from '@/storage/localstorage.service';
import _Vue from 'vue';

export default function apiPlugin(Vue: typeof _Vue, options?: any): void {
    const loggedApiAxios: AxiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
    });

    const guestApiAxios: AxiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
    });

    loggedApiAxios.interceptors.request.use(
        (config) => {
            const token = localStorageService.load('access_token');
            if (token) {
                config.headers = config.headers || {};
                config.headers['Authorization'] = `Bearer ${token}`;
                config.headers['Accept'] = 'application/vnd.vetolib.v1+json';
            }

            return config;
        },
        (error: AxiosError) => Promise.reject(error),
    );

    guestApiAxios.interceptors.request.use(
        (config) => {
            config.headers = config.headers || {};
            config.headers['Accept'] = 'application/vnd.vetolib.v1+json';
            
            return config;
        },
        (error: AxiosError) => Promise.reject(error),
    );

    loggedApiAxios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError<any>) => {
            const originalRequest: any = error.config;
            const originalHeaders = originalRequest.headers;
            const status = error.response?.status;
            const reason = error.response?.data?.reason;

            if (status === 401 && reason === 'token_expired' && !originalHeaders['X-Auth-Failed-No-Retry']) {
                return new Promise((resolve, reject) => {
                    getModule(AccountModule, store)
                        .refreshAccessToken()
                        .then(({access_token: accessToken}) => {
                            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                            originalRequest.headers['X-Auth-Failed-No-Retry'] = true;

                            return resolve(loggedApiAxios(originalRequest));
                        })
                        .catch((refreshError) => reject(refreshError))
                    ;
                });
            }

            return Promise.reject(error);
        },
    );

    Vue.prototype.$api = {
        account: new AccountService(loggedApiAxios, guestApiAxios),
        agenda: new AgendaService(loggedApiAxios, guestApiAxios),
        animal: new AnimalService(loggedApiAxios, guestApiAxios),
        booking: new BookingService(loggedApiAxios, guestApiAxios),
        calendar: new CalendarService(loggedApiAxios, guestApiAxios),
        contact: new ContactService(loggedApiAxios, guestApiAxios),
        openings: new OpeningsService(loggedApiAxios, guestApiAxios),
        organization: new OrganizationService(loggedApiAxios, guestApiAxios),
        place: new PlaceService(loggedApiAxios, guestApiAxios),
        search: new SearchService(loggedApiAxios, guestApiAxios),
        tuvcom: new TuvcomService(loggedApiAxios, guestApiAxios),
        instruction: new InstructionService(loggedApiAxios, guestApiAxios),
        rule: new RuleService(loggedApiAxios, guestApiAxios),
        client: new ClientService(loggedApiAxios, guestApiAxios),
    };
}
