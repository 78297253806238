export const ADD_BOOKING = '_addBooking';
export const ADD_BOOKING_COMMENT = '_addBookingComment';
export const ADD_BOOKING_FILES = '_addBookingFiles';
export const ADD_BOOKING_PROCESS = '_addBookingProcess';
export const ADD_OR_UPDATE_BOOKING = '_addOrUpdateBooking';
export const CANCEL_BOOKING = '_cancelBooking';
export const DELETE_BOOKING_FILE = '_deleteBookingFile';
export const MOVE_BOOKING = '_moveBooking';
export const RESET_BOOKING_PROCESS = '_resetBookingProcess';
export const SET_BOOKINGS_LIST = '_setBookingsList';
export const SET_CREATED_BOOKING = '_setCreatedBooking';
export const SET_DISPLAYED_BOOKING = '_setDisplayedBooking';
export const ADD_BOOKING_FILES_VET = '_setDisplayedBookingFilesVet';
export const DELETE_BOOKING_FILE_VET = '_deleteBookingFileVet';
