import Vue from 'vue';
import Router, {Route, RouteRecord, NavigationGuardNext} from 'vue-router';
import {store} from '@/store';
import {Position, PositionResult} from 'vue-router/types/router';
import {getModule} from 'vuex-module-decorators';
import {
    AccountModule,
    TuvcomModule,
} from '@/store/modules';

import {
    IMetaTagsDef,
    IMetaDataDef
} from '@/types';

Vue.use(Router);

// TODO : should be dynamic
const organizationTypes = [
    'cabinet-veterinaire',
    'cabinet-veterinaire-medico-chirurgical',
    'clinique-veterinaire',
    'centre-de-veterinaires-specialistes',
    'centre-hospitalier-veterinaire',
    'veterinaire-a-domicile',
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to: Route, from: Route, savedPosition: void | Position) {
        if (savedPosition) {
            // savedPosition is only available for popstate navigations.
            return savedPosition;
        } else {
            // scroll to anchor by returning the selector
            if (to.hash) {
                // bypass #1number check
                const element: HTMLElement = document.querySelector(to.hash) as HTMLElement;

                if (element) {
                    window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                    });
                } else if (/^#\d/.test(to.hash)) {
                    return {
                        selector: to.hash,
                    };
                }

                // if the returned position is falsy or an empty object,
                // will retain current scroll position.
                return;
            }

            return new Promise<PositionResult>((resolve) => {
                if (!to.meta?.preserveScrollPosition) {
                    // coords will be used if no selector is provided,
                    // or if the selector didn't match any element.
                    return resolve({
                        x: 0,
                        y: 0,
                    });
                }

                return resolve();
            });
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                absoluteHeader: true,
                title: 'Vetolib : Prenez Rendez-Vous en ligne avec votre vétérinaire',
                metaTags: [
                    {
                        name: 'description',
                        content: `Trouvez un vétérinaire disponible près de chez vous et prenez rendez vous en quelques clics. C'est facile et gratuit !`,
                    },
                ],
            },
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
        },
        {
            path: '/connexion',
            name: 'login',
            meta: {
                requiresGuest: true,
            },
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/inscription',
            name: 'register',
            meta: {
                requiresIncompleteRegistration: true,
            },
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
        },
        {
            path: '/reinitialiser',
            name: 'reset',
            meta: {
                requiresGuest: true,
            },
            component: () => import(/* webpackChunkName: "reset" */ './views/Reset.vue'),
        },
        {
            path: '/a-propos',
            name: 'about',
            components: {
                default: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
            },
        },
        {
            path: '/faq',
            name: 'faq',
            components: {
                default: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
                subheader: () => import(/* webpackChunkName: "faq" */ './components/layout/TheHeaderStatic.vue'),
            },
            props: {
                subheader: {
                    title: 'Questions fréquentes',
                },
            },
        },
        {
            path: '/cgu',
            name: 'terms',
            components: {
                default: () => import(/* webpackChunkName: "terms" */ './views/Terms.vue'),
                subheader: () => import(/* webpackChunkName: "terms" */ './components/layout/TheHeaderStatic.vue'),
            },
            props: {
                subheader: {
                    title: 'Conditions générales d\'utilisation',
                },
            },
        },
        {
            path: '/santevet',
            name: 'santevet',
            components: {
                default: () => import(/* webpackChunkName: "terms" */ './views/Santevet.vue'),
            },
        },
        {
            path: '/legal',
            name: 'legal',
            components: {
                default: () => import(/* webpackChunkName: "privacy" */ './views/Legal.vue'),
            },
            props: {
                subheader: {
                    title: 'Mentions légales',
                },
            },
        },
        {
            path: '/rgpd',
            name: 'rgpd',
            components: {
                default: () => import(/* webpackChunkName: "terms" */ './views/Rgpd.vue'),
            },
            props: {
                subheader: {
                    title: 'Rgpd',
                },
            },
        },

        {
            path: '/nous-contacter',
            name: 'contact',
            components: {
                default: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
                subheader: () => import(/* webpackChunkName: "contact" */ './components/layout/TheHeaderStatic.vue'),
            },
            props: {
                subheader: {
                    title: 'Nous contacter',
                },
            },
        },
        {
            path: '/mon-compte',
            name: 'account',
            component: () => import(/* webpackChunkName: "account" */ './views/Account.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'mes-rendez-vous',
                    name: 'appointments',
                    component: () => import(/* webpackChunkName: "account" */ './views/Appointments.vue'),
                    meta: {
                        preserveScrollPosition: true,
                        title: 'Vetolib - Mes rendez-vous',
                    },
                    children: [
                        {
                            path: ':id',
                            name: 'appointment',
                            component: () => import(/* webpackChunkName: "account" */ './views/Appointment.vue'),
                            meta: {
                                title: 'Vetolib - fiche rendez-vous',
                            },
                        },
                    ],
                },
                {
                    path: 'mes-animaux',
                    name: 'animals',
                    component: () => import(/* webpackChunkName: "account" */ './views/Animals.vue'),
                    meta: {
                        title: 'Vetolib - Mes animaux',
                    },
                },
                {
                    path: 'mon-profil',
                    name: 'profile',
                    component: () => import(/* webpackChunkName: "account" */ './views/Profile.vue'),
                    meta: {
                        title: 'Vetolib - Mon profil',
                    },
                },
            ],
        },
        {
            path: '/recherche',
            name: 'search',
            component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
        },
        {
            path: '/tuvcom',
            name: 'tuvcom',
            component: () => import(/* webpackChunkName: "tuvcom" */ './views/Tuvcom.vue'),
        },
        {
            path: '/prendre-rendez-vous',
            name: 'booking',
            components: {
                default: () => import(/* webpackChunkName: "booking" */ './views/BookingFunnel.vue'),
            },
            children: [
                {
                    path: 'details',
                    name: 'step-details',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingDetails.vue'),
                    meta: {
                        title: 'Vetolib - détail du rendez-vous',
                    },
                },
                {
                    path: 'connexion',
                    name: 'step-logger',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingLogger.vue'),
                    meta: {
                        title: 'Vetolib - Connexion/Inscription',
                    },
                },
                {
                    path: 'animal',
                    name: 'step-animal',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingAnimal.vue'),
                    meta: {
                        title: 'Vetolib - ajouter un animal au rendez-vous',
                    },
                },
                {
                    path: 'informations',
                    name: 'step-information',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingInformations.vue'),
                    meta: {
                        title: 'Vetolib - ajouter des informations supplémentaires',
                    },
                },
                {
                    path: 'assurance',
                    name: 'step-insurance',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingInsurance.vue'),
                    meta: {
                        title: 'Vetolib - ajouter des informations supplémentaires',
                    },
                },
                {
                    path: 'resume',
                    name: 'step-summary',
                    component: () => import(/* webpackChunkName: "booking" */ './components/bookingFunnel/BookingSummary.vue'),
                    meta: {
                        title: 'Vetolib - Résumé du rendez-vous',
                    },
                },
            ],
        },
        {
            path: `/:type(${organizationTypes.join('|')})/:city/:organizationSlug/:veterinarianSlug`,
            name: 'veterinarian',
            component: () => import(/* webpackChunkName: "veterinarian" */ './views/Veterinarian.vue'),
            meta: {
                title: (route: Route) => `Vetolib : Prenez rdv avec ${route.params.veterinarianSlug.replace('-', ' ')} chez ${route.params.organizationSlug.replace('-', ' ')} à ${route.params.city.replace('-', ' ')}`,
            },
        },
        {
            // tslint:disable-next-line:max-line-length
            path: `/:type(${organizationTypes.join('|')})/:city/:slug`,
            name: 'organization',
            component: () => import(/* webpackChunkName: "organization" */ './views/Organization.vue'),
            meta: {
                title: (route: Route) => `Vetolib : Prenez rendez-vous en ligne chez ${route.params.slug.replace('-', ' ')} à ${route.params.city.replace('-', ' ')}`,
            },
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
});

const accountModule = getModule(AccountModule, store);
const tuvcomModule = getModule(TuvcomModule, store);

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    // Change title and description
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => {
        if (el) {
            (el.parentNode as HTMLElement).removeChild(el);
        }
    });

    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags
            .map((tagDef: IMetaTagsDef) => {
                const tag = document.createElement('meta');

                Object.keys(tagDef).forEach((key) => {
                    if (key === 'name' || key === 'content') {
                        tag.setAttribute(key, tagDef[key]);
                    }
                });

                // We use this to track which meta tags we create, so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '');

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach((tag: HTMLElement) => document.head.appendChild(tag))
        ;
    }

    // Handle tuvcom token
    if (to.matched.some((record: RouteRecord) => record.name === 'tuvcom')) {
        if (!to.query.token) {
            return next({name: 'home'});
        }

        tuvcomModule.setToken(to.query.token as string);
    } else {
        tuvcomModule.clearState();
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresAuth)) {
        if (!accountModule.isLoggedIn) {
            return next({name: 'login'});
        }

        if (accountModule.loggedUser?.account?.type === 'veterinarian') {
            return next({name: 'home'});
        }

        return next();
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresGuest)) {
        if (accountModule.isLoggedIn) {
            return next({name: 'home'});
        }

        return next();
    }

    if (to.matched.some((record: RouteRecord) => record.meta.requiresIncompleteRegistration)) {
        if (!accountModule.isLoggedIn) {
            return next();
        }

        if (accountModule.loggedAccount?.status === 'pending_details') {
            return next();
        }

        if (accountModule.loggedAccount?.status === 'pending_validation') {
            return next();
        }

        return next({name: 'home'});
    }

    return next();
});

// To be able to use route params in meta
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const {title} = to.meta as IMetaDataDef;
    document.title = typeof title === 'function' ? title(to) : 'Vetolib : Prenez Rendez-Vous en ligne avec votre vétérinaire';

    // Fix scroll
    document.documentElement.style.overflow = 'auto';
    next();
});

export default router;
