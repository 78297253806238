import {IPlacePosition, IPlaceSuggestion} from '@/types';

import AbstractService from './abstract.service';

export class PlaceService extends AbstractService {
    public placeAutocomplete(val: string) {
        return new Promise<IPlaceSuggestion[]>((resolve, reject) => {
            this.guestApi({
                url: `/places?q=${encodeURIComponent(val)}&raw_data[]=address`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data.suggestions);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }

    public reverseGeocode(coords: IPlacePosition) {
        return new Promise<IPlaceSuggestion>((resolve, reject) => {
            this.guestApi({
                url: `/places/reverse_geocode::${coords.latitude},${coords.longitude}`,
                method: 'GET',
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                })
            ;
        });
    }
}
